import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

import "./c2.css";
import "./conference.css";
import "opentok-solutions-css";
// import SideBar from "../SideBar/sidebar";
// import Content from "../SideBar/content";
import AccCore from "opentok-accelerator-core";
import jwt_decode from "jwt-decode";
import classNames from "classnames";
// import config from "../config.json";
// import { GetSession } from "../configuration";
// import "bootstrap/dist/css/bootstrap.min.css";
// import logo from "../assets/Logo.png";
// import Cv from "../assets/cv.png";
// import TestResults from "../assets/TestResult.png";
import Breifcase from "../assets/Icon_briefcase.svg";
import RightMark from "../assets/right-mark.png";
import WrongMark from "../assets/Icon-close.svg";
import Cash from "../assets/cash.svg";
import Location from "../assets/location.svg";
// import { Rating } from "react-simple-star-rating";
// import Draggable from "react-draggable";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let otCore;
const otCoreOptions = {
  credentials: {
    apiKey: "",
    sessionId: "",
    token: "",
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    console.log(pubSub, "pubSub");
    console.log(type, "type");
    console.log(data, "data");
    console.log(stream, "stream");
    return {
      publisher: {
        camera: "#cameraPublisherContainer",
        screen: "#screenPublisherContainer",
      },
      subscriber: {
        camera: "#cameraSubscriberContainer",
        screen: "#screenSubscriberContainer",
      },
    }[pubSub][type];
  },
  controlsContainer: "#controls",
  packages: ["screenSharing", "annotation"],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: ["David", "Paul", "Emma", "George", "Amanda"][
      (Math.random() * 5) | 0
    ], // eslint-disable-line no-bitwise
    waitingMessage: "Messages will be delivered when other users arrive",
    container: "#chat",
  },
  screenSharing: {
    extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: "append",
      width: "100%",
      height: "100%",
      showControls: false,
      style: {
        buttonDisplayMode: "off",
      },
      videoSource: "window",
      fitMode: "contain", // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: ".App-video-container",
      subscriber: ".App-video-container",
    },
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const {
    active,
    meta,
    localAudioEnabled,
    localVideoEnabled,
    localScreenEnabled,
  } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    sharingScreen: sharingScreen,
    controlClass: classNames("App-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("ots-video-control circle shareScreen", {
      hidden: !active || !sharingScreen,
      muted: !localScreenEnabled || !sharingScreen,
      shareScreen: localScreenEnabled && sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: !active || !activeCameraSubscribers },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="App-mask">
    {/* <Spinner /> */}
    <div className="message with-spinner">Connecting</div>
  </div>
);
const DisconnectedMask = (type) => (
  <div className="App-mask">
    {/* <Spinner /> */}
    <div className="message with-spinner">Call Completed</div>
    {type == "HOST" && <div>Please submit your feedback</div>}
  </div>
);

const startCallMask = (start) => (
  <div className="App-mask">
    <button className="message button clickable" onClick={start}>
      Click to Start Call{" "}
    </button>
  </div>
);

class Conference extends React.Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
    this.token = this.urlParams?.get("token");
    // Moblie first
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      localScreenEnabled: true,
      testResults: false,
      jobDetails: false,
      isOpen: false,
      isMobile: true,
      showMsg: false,
      error: null,
      showIframe: false,
      hangout: false,
      showLink: null,
      showData: [],
      interval: true,
      callOnce: true,
    };

    this.previousWidth = -1;

    this.toggle = this.toggle.bind(this);
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.toggleLocalScreen = this.toggleLocalScreen.bind(this);
    this.startHeartbeat = this.startHeartbeat.bind(this);
  }
  onGetTestResults = () => {
    this.setState({ testResults: !this.state.testResults });
  };
  onGetJobDetails = () => {
    this.setState({ jobDetails: !this.state.jobDetails });
  };
  onGetDocs = () => {
    this.setState({ showIframe: !this.state.showIframe });
  };

  openIframe = (link, label) => {
    if (label == "Resume") {
      //   if (window.location.protocol == "http:") {
      window.open(link);
      //   } else {
      //     this.setState({ showIframe: !this.state.showIframe });
      //     this.setState({ showLink: link });
      //   }
    } else {
      this.setState({ showIframe: !this.state.showIframe });
      this.setState({ showLink: link });
    }
  };

  // handleRating = (rate) => {
  //   //setRating(rate)
  //   this.setState({ rating: rate });
  //   // other logic
  // };

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile,
      });
    }

    this.previousWidth = width;
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    if (this.token !== null) {
      const decoded = jwt_decode(this.token);
      console.log(decoded, "decoded");
      const arr = JSON.parse(decoded.encoded_data);
      console.log(arr, "array");
      this.setState({ showData: arr });

      this.setSession(arr);
      //this.startHeartbeat();
    }
    // console.log(this.state.showData)
    //this.getSession();
    //  this.getDataToShow();
  }

  setSession(data) {
    otCoreOptions.credentials.apiKey = data.vid_apikey;
    otCoreOptions.credentials.sessionId = data.vid_sessionid;
    otCoreOptions.credentials.token = data.vid_tokenid;
    this.joinedCall(otCoreOptions, data);
    //  this.setCoreOptions(otCoreOptions);
  }

  joinedCall(otCoreOptions, data) {
    axios
      .post(data.joined_url)
      .then((result) => {
        console.log(result, "startcall");
        this.callOneTimeHeartbeat(otCoreOptions, data);
      })
      .catch((err) => {
        console.log(err, "startcall");
      });
  }

  callOneTimeHeartbeat(otCoreOptions, data) {
    const callOnceId = setInterval(() => {
      if (this.state.callOnce == true) {
        axios
          .post(data.heartbeat_url)
          .then((result) => {
            if (
              result.data.response.data.callStatus == "INPROGRESS" ||
              result.data.response.data.callStatus == "ABORT"
            ) {
              this.setState({ callOnce: false });
              this.setCoreOptions(otCoreOptions, data);
            }
          })
          .catch((err) => {
            console.log(err, " err heartbeat");
          });
      }
    }, 5000);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  setCoreOptions(otCoreOptions, data) {
    otCore = new AccCore(otCoreOptions);
    otCore
      .connect()
      .then(() => {
        //  this.setState({ connected: true });

        // const timer = setTimeout(() => {
        this.setState({ connected: true });
        this.setState({ active: true });

        otCore
          .startCall()
          .then(({ publishers, subscribers, meta }) => {
            this.setState({ publishers, subscribers, meta, active: true });
          })
          .catch((error) => console.log(error));

        this.startHeartbeat(data);

        //   axios
        //     .post(this.state.showData.joined_url)
        //     .then((result) => {
        //       console.log(result, "startcall");
        //       this.startHeartbeat();
        //     })
        //     .catch((err) => {
        //       console.log(err, "startcall");
        //     });
        // }, 2000);
        // return () => clearTimeout(timer);
      })
      .catch((err) => {
        console.log(err.message, "err");
      });
    const events = [
      "subscribeToCamera",
      "unsubscribeFromCamera",
      "subscribeToScreen",
      "unsubscribeFromScreen",
      "startScreenShare",
      "endScreenShare",
    ];

    events.forEach((event) =>
      otCore.on(event, ({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta });
      })
    );
  }

  startHeartbeat(data) {
    const timerId = setInterval(() => {
      if (this.state.interval == true) {
        axios
          .post(data.heartbeat_url)
          .then((result) => {
            console.log(result, "heartbeat");
          })
          .catch((err) => {
            console.log(err, " err heartbeat");
          });
      }
    }, 5000);
  }

  startCall() {
    otCore
      .startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true });
      })
      .catch((error) => console.log(error));

    axios
      .post(this.state.showData.joined_url)
      .then((result) => {
        console.log(result, "startcall");
      })
      .catch((err) => {
        console.log(err, "startcall");
      });
  }

  endCall() {
    otCore.endCall();
    this.setState({ active: false });
    this.setState({ hangout: true });
    console.log(otCore, "otcore");

    axios
      .post(this.state.showData.hangup_url)
      .then((result) => {
        console.log(result, "endCall");
        this.setState({ interval: false });
      })
      .catch((err) => {
        console.log(err, "endcall");
      });
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  toggleLocalScreen() {
    console.log("toggleLocalscreen function called");
    console.log(otCore, "otcore");
    otCore.toggleLocalScreen(!this.state.localScreenEnabled);
    this.setState({ localScreenEnabled: !this.state.localScreenEnabled });
  }

  render() {
    console.log(otCore);
    const {
      connected,
      active,
      testResults,
      showIframe,
      error,
      showMsg,
      hangout,
      jobDetails,
      showData,
      showLink,
    } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);
    const { sharingScreen } = containerClasses(this.props);
    return (
      <>
        {/* <div className="App wrapper">
        <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
        <Content toggle={this.toggle} isOpen={this.state.isOpen} />
      </div> */}

        <div className="App">
          <div className="App-header">
            <img src={showData?.logo_url} className="App-logo" alt="logo" />
          </div>
          <div className="d-flex two-container">
            <div
              className={
                testResults || showIframe || jobDetails
                  ? "App-main w-70"
                  : "App-main w-90"
              }
            >
              <div className="App-video-container">
                {!connected && !hangout && connectingMask()}
                {connected &&
                  !active &&
                  !hangout &&
                  startCallMask(this.startCall)}
                {hangout && DisconnectedMask(showData?.user_type)}

                <div
                  id="cameraPublisherContainer"
                  className={cameraPublisherClass}
                />
                <div
                  id="screenPublisherContainer"
                  className={screenPublisherClass}
                />
                <div
                  id="cameraSubscriberContainer"
                  className={cameraSubscriberClass}
                />
                <div
                  id="screenSubscriberContainer"
                  className={screenSubscriberClass}
                />
              </div>
              <div id="controls" className={controlClass}>
                <div className={localCallClass} onClick={this.endCall} />
                {showData?.config?.mute && (
                  <div
                    className={localAudioClass}
                    onClick={this.toggleLocalAudio}
                  />
                )}
                {showData?.config?.video && (
                  <div
                    className={localVideoClass}
                    onClick={this.toggleLocalVideo}
                  />
                )}
                {/* {showData?.config?.share_screen && (
                  <div
                    title={screenPublisherClass.includes("shareScreen") ? <p>stop screen-sharing</p> : <p>start screen-sharing</p>}
                    className={screenPublisherClass}
                    // onClick={this.toggleLocalScreen}
                    id="screenPublisherContainer startScreenSharing"
                  ></div>
                )} */}
              </div>
              <div id="chat" className="App-chat-container" />
            </div>

            <div className="main_get-info">
              {showData?.icon_details?.map(
                (item, index) =>
                  // <div>hi</div>
                  !showIframe &&
                  item.redirect_url !== "" && (
                    <div className="get-info" key={index}>
                      <div className="d-flex jet-con-cen">
                        <img
                          src={item.icon_link}
                          width="35"
                          onClick={() =>
                            this.openIframe(
                              item.redirect_url,
                              item.icon_label_name
                            )
                          }
                        />
                      </div>
                      <span
                        className="d-flex jet-con-cen font12"
                        onClick={() =>
                          this.openIframe(
                            item.redirect_url,
                            item.icon_label_name
                          )
                        }
                      >
                        {item.icon_label_name}
                      </span>
                    </div>
                  )
              )}
              {/* <div className="get-info mt-2" onClick={this.onGetJobDetails}>
                    <div className="d-flex jet-con-cen">
                      <img src={Breifcase} width="35" />
                    </div>
                    <span className="d-flex jet-con-cen font12">Job Info</span>
                  </div>
                  <div
                    className="get-info mt-2"
                    onClick={this.onGetTestResults}
                  >
                    <div className="d-flex jet-con-cen">
                      <img src={TestResults} width="35" />
                    </div>
                    <span className="d-flex jet-con-cen font12">
                      Test Results
                    </span>
                  </div> */}
            </div>

            {/* {!testResults && !showIframe && !hangout && !jobDetails ? (
              <>
                <div>
                  <div className="get-info" onClick={this.onGetDocs}>
                    <div className="d-flex jet-con-cen">
                      <img src={Cv} width="35" />
                    </div>
                    <span className="d-flex jet-con-cen font12">Resume</span>
                  </div>
                  <div className="get-info mt-2" onClick={this.onGetJobDetails}>
                    <div className="d-flex jet-con-cen">
                      <img src={Breifcase} width="35" />
                    </div>
                    <span className="d-flex jet-con-cen font12">Job Info</span>
                  </div>
                  <div
                    className="get-info mt-2"
                    onClick={this.onGetTestResults}
                  >
                    <div className="d-flex jet-con-cen">
                      <img src={TestResults} width="35" />
                    </div>
                    <span className="d-flex jet-con-cen font12">
                      Test Results
                    </span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )} */}
            {showIframe && (
              <>
                <iframe className="w-30" src={showLink}></iframe>
                {/* <iframe
                  className="w-30"
                  src="https://docs.google.com/document/d/1SR4HITey92XWxoMSlPlffca-5uqDBJcQz2w3PTCWiGk/edit"
                ></iframe> */}
                <span onClick={this.onGetDocs} className="get-info">
                  X
                </span>
              </>
            )}
            {testResults && (
              // <Draggable>

              <div className="feedback">
                <div className="d-flex just-center">
                  <strong className="font20">Test results</strong>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.onGetTestResults}
                  >
                    X
                  </span>
                </div>
                <div className="d-flex just-center mt-1">
                  <div className="d-flex">
                    <label className="font12 grayColor">
                      Test submitted on 04-Jun-2023
                    </label>
                  </div>
                  <div className="d-flex">
                    <label className="font12 grayColor">
                      Time Taken: 00 hrs 25mins
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <strong className="font14">Score: 125/200</strong>
                </div>
                <div className="d-flex just-center mt-1">
                  <div className="d-flex">
                    <label className="font12 grayColor">
                      Total Questions: 25{" "}
                    </label>
                  </div>
                  <div className="d-flex">
                    <label className="font12 grayColor">15</label>&nbsp;&nbsp;
                    <img src={RightMark} className="mark-size" />
                    &nbsp;&nbsp;
                    <label className="font12 grayColor">10</label>&nbsp;&nbsp;
                    <img src={WrongMark} className="mark-size" />
                  </div>
                </div>
                <div className="set-scroll">
                  <div className="question-list">
                    <div className="d-flex just-center mt-1 p-2">
                      <img src={RightMark} className="mark-size" />
                      <span className="font12">Score: 5</span>
                    </div>
                    <div className="border-bottom"></div>
                    <div className="p-2">
                      <div>
                        <strong className="font14">Question 1</strong>
                      </div>
                      <div>
                        <label className="font12 mt-2">
                          What conditions should exist in a Rental Agreement for
                          application of the Rent Equalisation? Select ALL the
                          correct answers
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="checkbox" checked /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Increase in the rental amount where the escalation
                          rate is below the inflation rate
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="checkbox" checked /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Existence of Lock in period
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="checkbox" /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Both the parties can withdraw from the contract
                          without an prior notice and no obligation on them
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="checkbox" /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Both the parties can withdraw from the contract
                          without an prior notice and no obligation on them
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="question-list">
                    <div className="d-flex just-center mt-1 p-2">
                      <img src={WrongMark} className="mark-size" />
                      <span className="font12">Score: 0</span>
                    </div>
                    <div className="border-bottom"></div>
                    <div className="p-2">
                      <div>
                        <strong className="font14">Question 2</strong>
                      </div>
                      <div>
                        <label className="font12 mt-2">
                          What conditions should exist in a Rental Agreement for
                          application of the Rent Equalisation? Select ALL the
                          correct answers
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="radio" checked /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Increase in the rental amount where the escalation
                          rate is below the inflation rate
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="radio" /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Existence of Lock in period
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="radio" /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Both the parties can withdraw from the contract
                          without an prior notice and no obligation on them
                        </label>
                      </div>
                      <div className="d-flex mt-1">
                        <input type="radio" /> &nbsp; &nbsp;
                        <label className="font10 d-flex">
                          Both the parties can withdraw from the contract
                          without an prior notice and no obligation on them
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              // </Draggable>
            )}
            {/* {hangout && (
              <div className="feedback">
                <div className="d-flex just-center">
                  <strong className="font20">Akhil Anand</strong>
                  <span style={{cursor:"pointer"}} onClick={this.onGetDetails}>X</span>
                </div>
                <div className="d-flex just-center mt-4">
                  <div className="d-flex">
                    <img src={Cv} alt="cv" />
                    <label className="font14">Click to view resume</label>
                  </div>
                  <div className="d-flex">
                    <img src={Cv} alt="cv" />
                    <label className="font14">Click to view job details</label>
                  </div>
                </div>

                <div className="mt-4">
                  <strong className="font14">Rate the Candidate</strong>

                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <label className="font12 mr">Core Java</label>

                      <Rating onClick={this.handleRating()} size={25} />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <label className="font12 mr">J2EE</label>

                      <Rating onClick={this.handleRating()} size={25} />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <label className="font12 mr">Database</label>

                      <Rating onClick={this.handleRating()} size={25} />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <label className="font12 mr">Communication</label>

                      <Rating onClick={this.handleRating()} size={25} />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <label className="font12 mr">Cultural Fit</label>

                      <Rating onClick={this.handleRating()} size={25} />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <strong className="font14">Feedback</strong>
                  <div>
                    <textarea
                      placeholder="Enter your comments here"
                      style={{ width: "100%" }}
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div>
                  <button className="submit">Submit</button>
                </div>
              </div>
            )} */}
            {jobDetails && (
              <div className="feedback">
                <div className="d-flex just-center">
                  <strong className="font20"></strong>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.onGetJobDetails}
                  >
                    X
                  </span>
                </div>
                <div className="set-des-scroll">
                  <div>
                    <span className="font14">Job Applied For</span>
                  </div>
                  <div className="mt-2">
                    <strong className="font18">Senior Analyst</strong>
                    <br />
                    <span className="font12">XYZ Corporation Pvt. Ltd.</span>
                  </div>
                  <div className="mt-2 d-flex img-size">
                    <div className="w-50">
                      <img src={Location} />
                      &nbsp;
                      <span className="font12">Banglore</span>
                    </div>
                    <div>
                      <img src={Breifcase} />
                      &nbsp;
                      <span className="font12">3-5 years</span>
                    </div>
                  </div>
                  <div className="mt-2 d-flex img-size">
                    <div className="w-50">
                      <img src={Breifcase} />
                      &nbsp;
                      <span className="font12">Full Time</span>
                    </div>
                    <div>
                      <img src={Cash} />
                      &nbsp;
                      <span className="font12">Not Disclosed</span>
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <b className="font12 get-width">Location</b>
                      <span className="job-info font12">Mumbai | India</span>
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <b className="font12 get-width">Required Domain Skills</b>
                      <span className="job-info font12">Core Java</span>
                      &nbsp;&nbsp;
                      <span className="job-info font12">J2EE</span>
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <b className="font12 get-width">Good to have Skills</b>
                      <span className="job-info font12">Core Java</span>
                      &nbsp;&nbsp;
                      <span className="job-info font12">J2EE</span>{" "}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100">
                      <b className="font12 get-width">Soft Skills</b>
                      <span className="job-info font12">Communication</span>
                      &nbsp;&nbsp;
                      <span className="job-info font12">Culture Fit</span>{" "}
                    </div>
                  </div>

                  <div className="mt-4">
                    <b className="font14">Description</b>
                    <div>
                      <span className="font12">
                        As a Full Stack Developer, you should be comfortable
                        around both front- end and back -end coding languages,
                        development frameworks and third- party libraries. You
                        should also be a team player with a knack for visual
                        design and utility.
                      </span>
                      <br />
                      <b className="font14 mt-2">Responsibilities</b>
                      <br />
                      <span className="font12 mt-2">
                        Work with development teams and product managers to
                        ideate software solutions Design client-side and
                        server-side architecture Build the front-end of
                        applications through appealing visual design Develop and
                        manage well-functioning databases and applications Write
                        effective APIs Test software to ensure responsiveness
                        and efficiency Troubleshoot, debug and upgrade software
                        Create security and data protection settings Build
                        features and applications with a mobile responsive
                        design Write technical documentation Work with data
                        scientists and analysts to improve software.
                      </span>
                      <br />
                      <b className="font14 mt-2">Requirements and skills</b>
                      <br />
                      <span className="font12 mt-2">
                        Proven experience as a Full Stack Developer or similar
                        role Experience developing desktop and mobile
                        applications Familiarity with common stacks Knowledge of
                        multiple front-end languages and libraries (e.g. HTML/
                        CSS, JavaScript, XML, jQuery) Knowledge of multiple
                        back-end languages (e.g. C#, Java, Python) and
                        JavaScript frameworks (e.g. Angular, React, Node.js)
                        Familiarity with databases (e.g. MySQL, MongoDB), web
                        servers (e.g. Apache) and UI/UX design Excellent
                        communication and teamwork skills Great attention to
                        detail Organizational skills An analytical mind Degree
                        in Computer Science, Statistics or relevant field
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Conference;
